<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form :model="formLabelAlign" class="from">
            <el-form-item label="名称：" label-width="60px" style="width: 310px;">
              <el-input placeholder="请输入名称" size="mini" class="form-inp" v-model="formLabelAlign.name"></el-input>
            </el-form-item>

            <el-form-item label="时间选择：" label-width="90px" style="width: 550px;">
              <div>
                <el-date-picker value-format="yyyy-MM-dd" v-model="formLabelAlign.bengin_time" type="date"
                  placeholder="开始日期" @change="DateChange()">
                </el-date-picker>
                至
                <el-date-picker value-format="yyyy-MM-dd" v-model="formLabelAlign.end_time" type="date" placeholder="结束日期"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="状态：" label-width="60px">
              <el-select v-model="formLabelAlign.status" placeholder="请选择状态">
                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="search_button">
            <div>
              <el-button @click="way_tableData(true)" type="primary" icon="el-icon-search">查询</el-button>
              <el-button @click="on_reset" icon="el-icon-refresh-left">重置</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个模板</span>
            <span class="span2">已选择 {{ more_List.length }} 个模板</span>
            <i class="span_i el-icon-refresh" @click="way_tableData()">数据更新</i>
          </p>
          <div>
            <el-button type="primary" @click="on_pop_addplus()" icon="el-icon-plus">新建</el-button>
            <el-button @click="on_deleteList" icon="el-icon-close">删除</el-button>
            <!-- <el-button @click="on_export">导出</el-button> -->
          </div>
        </div>
        <div>
          <el-table highlight-current-row @current-change="way_singleselect" @selection-change="way_moreselect"
            :data="table_List" :header-cell-style="{ background: '#f8f8f9' }" style="flex: 1">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="department_name" label="部门"></el-table-column>
            <el-table-column prop="score" label="增减分"></el-table-column>
            <el-table-column prop="handle_time" label="增减分日期"></el-table-column>
            <el-table-column prop="desc" label="原因"></el-table-column>

            <el-table-column prop="address" label="操作">
              <template slot-scope="scope">
                <el-link class="list_sty" type="primary" @click="on_pop_addplus(scope.row.id)">修改</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination @current-change="way_page" :page-size="page_size" layout="prev, pager, next, jumper"
            :total="total" :current-page="page">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 新建修改弹窗 -->
    <el-dialog :title="title" show-close :visible.sync="dialog_show" center>
      <el-form ref="formName" label-width="180px" :model="From_Data">
        <el-form-item label="部门：" prop="department_id" :rules="[{ required: true, message: '请选择部门' }]">
          <Bepartment class="form-inp" ref="Bepartment" v-model="From_Data.department_id" />
        </el-form-item>

        <el-form-item label="增减分日期：" prop="handle_time" :rules="[{ required: true, message: '请选择增减分日期' }]">
          <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" class="form-inp" default-time="12:00:00"
            v-model="From_Data.handle_time" type="datetime" align="right" placeholder="请选择增减分日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="分值：" prop="score" :rules="[{ required: true, message: '请选择输入分值' }]">
          <el-input type="number" placeholder="请输入分值" size="mini" class="form-inp" v-model="From_Data.score"></el-input>

          <span class="tisi"> *加分为正，扣分为负 </span>
        </el-form-item>

        <el-form-item label="原因：" prop="desc" :rules="[{ required: true, message: '请选择输入原因' }]">
          <el-input type="textarea" placeholder="请输入原因" size="mini" class="form-textarea"
            v-model="From_Data.desc"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          title
        }}</el-button>
        <el-button @click="dialog_show = false">返回</el-button>
      </div>
    </el-dialog>
    <!-- 专项检查模板 -->
  </div>
</template>

<script>
import table_minin from "../../assets/minin/table_minin";
import {
  scoreindex,
  scoredelete,
  scoresaveinfo,
  scoresave,
} from "../../assets/request/api";
import Bepartment from "../../components/Bepartment";

export default {
  name: "Special_inspection",
  mixins: [table_minin],
  components: {
    Bepartment,
  },
  data() {
    return {
      title: "",
      dialog_show: false,
      formLabelAlign: {
        bengin_time: "",
        end_time: "",
        name: "",
        status: "",
      },
      //新建编辑数据
      From_Data: {
        department_id: "",
        score: "",
        desc: "",
        handle_time: "",
      },
    };
  },
  created() {
    // this.is_enableUrl = spectionsaveInfo;
    this.url = scoreindex;
    this.delete_Url = scoredelete;
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "status_list";
    this.way_tableData();
  },
  methods: {
    //统计周期结束日期禁用
    DateChange(type) {
      if (!type) {
        this.formLabelAlign.end_time = "";
      }
      let that = this;
      this.pickerOptions = {
        //日期禁用
        disabledDate(date) {
          return that.$tools.Time_todetermine(
            date,
            that.formLabelAlign.bengin_time
          );
        },
      };
    },
    on_pop_addplus(id) {
      this.title = id ? "修改" : "新增";
      this.dialog_show = true;

      if (id) {
        this.From_Data.id = id;

        scoresaveinfo({ id }).then((res) => {
          let { department_id, handle_time, desc, score } = res.data.info;
          this.From_Data.score = score;
          this.From_Data.desc = desc;
          this.From_Data.handle_time = handle_time;
          this.From_Data.department_id = department_id;
          this.$refs.Bepartment.bepartment_List = department_id;
        });
      }
    },
    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { From_Data } = this;

          scoresave(From_Data).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              setTimeout(function () {
                that.way_tableData();
                that.dialog_show = false;
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}

.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}

.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.form-inp {
  width: 200px;
}

.paging {
  text-align: center;
  padding: 10px;
}

.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  button {
    margin-right: 10px;
  }

  p {
    display: flex;
    align-items: center;
  }
}

.span1 {
  font-size: 14px;
  color: #000000;
}

.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}

.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}

.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}

.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.list_sty {
  margin-right: 10px;
}

.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}

.form-textarea {
  width: 400px;
}

.tisi {
  font-size: 14px;
  color: #999;
}
</style>
